import React, {useState} from "react"
import web3 from "../images/tracks/web3-light.svg"
import nft from "../images/tracks/nft-light.svg"
import defi from "../images/tracks/defi-light.svg"
import gamefi from "../images/tracks/gamefi-light.svg"
import ecosystem from "../images/tracks/ecosystem-light.png";
import academy from "../images/tracks/academy-light.png";
import testImg from "../images/hackathons4/team-backup.png"
import builder from "../images/tracks/builder-light.png"
import ecofriendly from "../images/tracks/eco-friendly-light.svg"
import {CgClose} from "react-icons/all";
import HackerText from "./hackerText";

const Winnerss3 = ({data}) => {
    const [isOpen, setIsOpen] = useState(false);

    const displayModal = () => {
        return isOpen ? ' d-block' : ' ';
    }

    const selectTrack = (track) => {
        setIsOpen(true);
        setSelectedTrack(track);
        if (track.winnersList !== undefined) {

            let reversed = track.winnersList[0].slice();
            sortWinners(reversed);
            setSelectedSeason(track.headerButtonText[0]);
            setSelectedSeasonWinners(reversed);
        }

    }


    const [selectedSeason, setSelectedSeason] = useState([]);
    const selectSeasonWinners = (index, season) => {
        let reversed = selectedTrack.winnersList[index].slice();
        sortWinners(reversed);
        setSelectedSeason(season);
        setSelectedSeasonWinners(reversed);
    }

    const sortWinners = function (winners) {
        let copy = winners.slice();

    }


    const [selectedTrack, setSelectedTrack] = useState([]);
    const [selectedSeasonWinners, setSelectedSeasonWinners] = useState([]);

    let web3Season1Winners = [];
    let web3Season2Winners = [];
    let web3Season3Winners = [];

    let defiSeason1Winners = [];
    let defiSeason2Winners = [];
    let defiSeason3Winners = [];

    let nftSeason1Winners = [];
    let nftSeason2Winners = [];
    let nftSeason3Winners = [];

    let gamefiSeason1Winners = [];
    let gamefiSeason2Winners = [];
    let gamefiSeason3Winners = [];


    let ecosystemTechnicalSeason3Winners = [];
    let ecosystemCreativeSeason3Winners = [];

    let academyTechnicalSeason3Winners = [];
    let academyNonTechnicalSeason3Winners = [];

    let bonusPrizeSeason2Winners = [];
    let bonusPrizeSeason3Winners = [];


    for (let i = 0; i < data.nodes.length; i++) {
        let node = data.nodes[i];
        let season = node.hackathonSeasonsAndTracks.hackathonSeason;
        let track = node.hackathonSeasonsAndTracks.hackathonTrack;

        if (season.includes("Season 1")) {
            if (track.includes("Web3")) {
                web3Season1Winners.push(node);
            } else if (track.includes("DeFi")) {
                defiSeason1Winners.push(node);
            } else if (track.includes("NFT")) {
                nftSeason1Winners.push(node);
            } else if (track.includes("GameFi")) {
                gamefiSeason1Winners.push(node);
            }

        } else if (season.includes("Season 2")) {
            if (track.includes("Web3")) {
                web3Season2Winners.push(node);
            } else if (track.includes("DeFi")) {
                defiSeason2Winners.push(node);
            } else if (track.includes("NFT")) {
                nftSeason2Winners.push(node);
            } else if (track.includes("GameFi")) {
                gamefiSeason2Winners.push(node);
            } else {
                bonusPrizeSeason2Winners.push(node);
            }
        } else if (season.includes("Season 3")) {
            if (track.includes("Web3")) {
                web3Season3Winners.push(node);
            } else if (track.includes("DeFi")) {
                defiSeason3Winners.push(node);
            } else if (track.includes("NFT")) {
                nftSeason3Winners.push(node);
            } else if (track.includes("GameFi")) {
                gamefiSeason3Winners.push(node);
            } else if (track.includes("Ecosystem Technical")) {
                ecosystemTechnicalSeason3Winners.push(node);
            } else if (track.includes("Ecosystem Creative")) {
                ecosystemCreativeSeason3Winners.push(node);
            } else if (track.includes("Academy Technical")) {
                academyTechnicalSeason3Winners.push(node);
            } else if (track.includes("Academy Non-Technical")) {
                academyNonTechnicalSeason3Winners.push(node);
            } else {
                bonusPrizeSeason3Winners.push(node);
            }
        }
    }

    let tracks = [
        {
            title: "Web3",
            img: web3,
            disabled: false,
            headerButtonText: ['Season 1', 'Season 2', 'Season 3'],
            winnersList: [
                web3Season1Winners,
                web3Season2Winners,
                web3Season3Winners
            ]
        },
        {
            title: "DeFi",
            img: defi,
            disabled: false,
            headerButtonText: ['Season 1', 'Season 2', 'Season 3'],
            winnersList: [
                defiSeason1Winners,
                defiSeason2Winners,
                defiSeason3Winners
            ]
        },
        {
            title: "NFT",
            img: nft,
            disabled: false,
            headerButtonText: ['Season 1', 'Season 2', 'Season 3'],
            winnersList: [
                nftSeason1Winners,
                nftSeason2Winners,
                nftSeason3Winners
            ]
        },
        {
            title: "GameFi",
            img: gamefi,
            disabled: false,
            headerButtonText: ['Season 1', 'Season 2', 'Season 3'],
            winnersList: [
                gamefiSeason1Winners,
                gamefiSeason2Winners,
                gamefiSeason3Winners
            ]
        },
        {
            title: "Academy",
            img: academy,
            disabled: false,
            headerButtonText: ['Season 3 Technical', 'Season 3 Non-Technical'],
            winnersList: [
                academyTechnicalSeason3Winners,
                academyNonTechnicalSeason3Winners
            ]
        },
        {
            title: "Ecosystem",
            img: ecosystem,
            disabled: false,
            headerButtonText: ['Season 3 Creative', 'Season 3 Technical'],
            winnersList: [
                ecosystemCreativeSeason3Winners,
                ecosystemTechnicalSeason3Winners
            ]
        },
        {
            title: "Builders",
            img: builder,
            disabled: true,
            headerButtonText: [],
            winnersList: []
        },
        {
            title: "Eco-Friendly",
            img: ecofriendly,
            disabled: true,
            headerButtonText: [],
            winnersList: []
        },
    ]


    return (
        <>
            <div className="text-center row no-gutters winners-s3-lower">
                <HackerText text='All Previous Season Winners' id="winners"
                            className="section-header w-100 mt-5"
                            tag="h2" triggerStart="bottom 95%" speed="50"/>
                <p className="w-100 winners-s3-subheading">Congratulations to all of our previous season winners.</p>
            </div>
            <div className="text-center winners-s3 d-flex flex-wrap justify-content-center">
                {tracks.map((track => {
                    return <div className="winners-s3-track-card" key={"track-hackathon-" + track.title}>
                        <div className="">
                            <div className="d-flex align-items-center justify-content-center winners-s3-image">
                                <img src={track.img} alt={track.title}/>
                            </div>
                            <button disabled={track.disabled}
                                    className="w-100" id={"hackathon-view-winners-"+ track.title}
                                    onClick={function () {
                                        selectTrack(track);
                                    }}>{track.title}
                            </button>
                        </div>
                    </div>
                }))
                }
            </div>
            <div className={'winners-s3-modal' + displayModal()}>
                <div className="row no-gutters h-100 align-items-center justify-content-center">
                    <div className="winners-s3-modal-container">
                        <div className="winners-s3-modal-container-close cursor-pointer" onClick={function () {
                            setIsOpen(false)
                        }}><CgClose size="35px"/></div>
                        <div className="row no-gutters winners-s3-modal-container-header py-2 py-md-5 mx-2 mx-md-5">
                            <h4 className="col-md-3 col-12 text-center">{selectedTrack.title}</h4>
                            {
                                selectedTrack.headerButtonText !== undefined && selectedTrack.headerButtonText.map(((buttonText, index) => {
                                    let isActive = selectedSeason === buttonText ? 'active' : '';
                                    return <span onClick={() => selectSeasonWinners(index, buttonText)}
                                                 className={"my-3 my-md-0 " + isActive}>{buttonText}</span>
                                }))
                            }
                        </div>
                        <div className="my-3 winners-s3-modal-content-wrapper text-center row no-gutters w-100">

                            {selectedSeasonWinners.length > 0 && selectedSeasonWinners.map((track) => {
                                let project = track.title.split(':')[1].split(" by ")[0];
                                let teamName = track.title.split(':')[1].split(" by ")[1];
                                let teamImg = track.hackathonProjectInfo.hackathonTeamPic != null ? track.hackathonProjectInfo.hackathonTeamPic.sourceUrl : testImg;
                                return (
                                    <div key={'key-winner-modal-' + track.title}
                                         className="mx-3 mx-md-5 row no-gutters winners-s3-modal-content align-items-center py-1 py-md-0 w-100">
                                        <div
                                            className="col-md-2  col-12 offset-0 winners-s3-modal-content-prize offset-md-1">
                                            <span>{track.hackathonProjectInfo.winnersPrize}</span>
                                        </div>
                                        <div
                                            className="col-md-8 col-12 h-100 winners-s3-modal-content-spacer">
                                            <p className="winners-s3-modal-content-team text-left">
                                                <img className="winners-s3-modal-content-project-img" src={teamImg}
                                                     alt={track.title}/>
                                                <span
                                                    className="winners-s3-modal-content-project ">{project}</span><span>by</span><span>{teamName}</span>
                                            </p>

                                        </div>
                                    </div>
                                )
                            })
                            }


                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Winnerss3