import React from 'react';
import HackerText from '../components/hackerText'
import returnKey from "../images/hackathons4/return-key.svg"
import winnerBg from "../images/hackathons4/winner-bg.png";

const Winnerss3v2 = (props) => {


    let season1Winners = [];
    let season2Winners = [];
    let season3Winners = [];
    for (let i = 0; i < props.data.nodes.length; i++) {
        let winner = props.data.nodes[i];
        let season = winner.hackathonSeasonsAndTracks.hackathonSeason[0];
        let parsedByColon = "";
        let projectName = "";
        let team = "";
        if (!winner.title.includes("Ecosystem") && !winner.title.includes("Academy")) {
            parsedByColon = winner.title.split(":")[1].split(" by ")
            projectName = parsedByColon[0];
            team = parsedByColon[1];
        } else {
            parsedByColon = winner.title.split(":")[1].split(" by ")
            projectName = parsedByColon[0];
            team = parsedByColon[1];
        }

        winner.projectName = projectName;
        winner.team = team;


        if (season === "Season 1") {
            season1Winners.push(winner);
        } else if (season === "Season 2") {
            season2Winners.push(winner);
        } else if (season === "Season 3") {
            season3Winners.push(winner);
        }
    }


    return (
        <>
            <div className="text-center row no-gutters winners-s3 pt-5">
                <HackerText text='Winners' id="winners"
                            className="section-header w-100 mt-5"
                            tag="h2" triggerStart="bottom 95%" speed="50"/>
                <p className="w-100 winners-s3-subheading">Congratulations to our previous season
                    1st place winners</p>
            </div>
            <div className="d-flex justify-content-center winners-s3 flex-wrap">
                <div className="winners-s3-v2-card-left-wrap">
                    <div className="winners-s3-v2-card-left">
                        <div className="winners-s3-v2-card-cover">
                            <img src={winnerBg} alt="Winners background design"
                                 className="winners-s3-v2-card-cover-bg"/>
                            <div>
                                <img src={returnKey} alt={"Return Key"}/>
                                <h4 className="d-inline-block">Season 1</h4>
                            </div>
                        </div>
                        <div className="winners-s3-v2-card-back">
                            <div className="d-flex flex-wrap justify-content-center">
                                {
                                    season1Winners.map((winner => {
                                        return <div className="winners-s3-v2-card-back-winner" key={"winner-card-hackathon-s1-" + winner.title}>
                                                <div>
                                                    <span
                                                        className="winners-s3-v2-card-back-winner-header">{winner.hackathonSeasonsAndTracks.hackathonTrack}</span>
                                                </div>
                                                <div className="d-inline-block">
                                                    <span className="winners-s3-v2-card-back-winner-bg">
                                                            <img src={winner.hackathonProjectInfo.hackathonTeamPic.sourceUrl} alt={winner.team + " team"}/>
                                                    </span>
                                                    <h3 className="d-inline-block">{winner.projectName}</h3>
                                                    <h4>by {winner.team}</h4>
                                                </div>
                                            </div>
                                    }))
                                }

                            </div>
                        </div>
                    </div>
                    <div className="winners-s3-v2-card-left">
                        <div className="winners-s3-v2-card-cover">
                            <img src={winnerBg} alt="Winners background design"
                                 className="winners-s3-v2-card-cover-bg"/>
                            <div>
                                <img src={returnKey} alt={"Return Key"}/>
                                <h4 className="d-inline-block">Season 2</h4>
                            </div>
                        </div>
                        <div className="winners-s3-v2-card-back">
                            <div className="d-flex flex-wrap justify-content-center">
                                {
                                    season2Winners.map((winner => {
                                        return <div className="winners-s3-v2-card-back-winner" key={"winner-card-hackathon-s2-" + winner.title}>
                                                <div>
                                                    <span
                                                        className="winners-s3-v2-card-back-winner-header">{winner.hackathonSeasonsAndTracks.hackathonTrack}</span>
                                                </div>
                                                <div className="d-inline-block">
                                                    <span className="winners-s3-v2-card-back-winner-bg">
                                                            <img src={winner.hackathonProjectInfo.hackathonTeamPic.sourceUrl} alt={winner.team + " team"}/>
                                                    </span>
                                                    <h3 className="d-inline-block">{winner.projectName}</h3>
                                                    <h4>by {winner.team}</h4>
                                                </div>
                                            </div>
                                    }))
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="winners-s3-v2-card-right">
                    <div className="winners-s3-v2-card-cover">
                        <img src={winnerBg} alt="Winners background design" className="winners-s3-v2-card-cover-bg"/>
                        <div>
                            <img src={returnKey} alt={"Return Key"}/>
                            <h4 className="d-inline-block">Season 3</h4>
                        </div>
                    </div>
                    <div className="winners-s3-v2-card-back">
                        <div className="d-flex flex-wrap justify-content-center">
                            {
                                season3Winners.map((winner => {
                                    return <div className="winners-s3-v2-card-back-winner" key={"winner-card-hackathon-s3-" + winner.title}>
                                            <div>
                                                <span
                                                    className="winners-s3-v2-card-back-winner-header">{winner.hackathonSeasonsAndTracks.hackathonTrack}</span>
                                            </div>
                                            <div className="d-inline-block">
                                                  <span className="winners-s3-v2-card-back-winner-bg">
                                                    <img src={winner.hackathonProjectInfo.hackathonTeamPic.sourceUrl} alt={winner.team + " team"}/>
                                                  </span>
                                                <h3 className="d-inline-block">{winner.projectName}</h3>
                                                <h4>by {winner.team}</h4>
                                            </div>
                                        </div>
                                }))
                            }

                        </div>
                    </div>
                </div>


            </div>
        </>

    )
}

export default Winnerss3v2

