import React, {useState} from "react"
import web3light from "../images/tracks/web3-light.svg";
import defilight from "../images/tracks/defi-light.svg";
import nftlight from "../images/tracks/nft-light.svg";
import gamefilight from "../images/tracks/gamefi-light.svg";
import builderlight from "../images/tracks/builder-light.png";
import ecofriendlylight from "../images/tracks/eco-friendly-light.svg";

import returnImg from "../images/tracks/return.png";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";

const Trackss3 = () => {

    const tracks = [
        {
            trackImg: web3light,
            title: "Web3",
            text: "The future of the Web is in your hands. The judges are looking to developers for a contribution that will help transition this new technology and bring it to fruition."
        },
        {
            trackImg: defilight,
            title: "DeFi",
            text: "DeFi continues to be a crucial movement for the crypto space as it enables financial services to operate in an open, accessible, and transparent digital form. Looking for the next innovative DeFi solution that could provide accessible financial services to billions of people. "
        },
        {
            trackImg: nftlight,
            title: "NFT",
            text: "We are embracing creator economies by working to empower developers who create novel NFT applications. By giving them both financial benefits and technical support so that their work can reach its full potential. "
        },
        {
            trackImg: gamefilight,
            title: "GameFi",
            text: "There’s nothing like feeling the victory when your game comes out on top. This track is all about developing the next big game idea. With the next generation of game, entertainment meets blockchain."
        },
        {
            trackImg: builderlight,
            title: "Builder",
            text: "A new track for returning projects that continued development on TRON/BTTC and create significant updates for their project."
        },
        {
            trackImg: ecofriendlylight,
            title: "Eco-Friendly",
            text: "Eco-friendly track is for blockchain projects related to environmental, climate control, energy, etc."
        },
    ]

    const [carouselCounter, setCarouselCounter] = useState(0);
    let movementAmount = 100;

    const moveCarousel = (direction) => {
        if (direction === "right") {
            let total = carouselCounter - movementAmount;
            document.getElementById("tracks-s3-carousel").style.transform = 'translateX(' + total + 'vw)';
            setCarouselCounter(total);
        } else if (direction === "left") {
            let total = carouselCounter + movementAmount;
            document.getElementById("tracks-s3-carousel").style.transform = 'translateX(' + total + 'vw)';
            setCarouselCounter(total);
        }
    }
    const hideLeftArrow = function (counter) {
        return counter === 0 ? " d-none " : "";
    }
    const hideRightArrow = function (counter) {
        return counter === -500 ? " d-none " : "";
    }

    return (
        <div className="tracks-s3-wrapper">
            <FaArrowLeft size="45px" onClick={() => {
                moveCarousel('left')
            }} className={hideLeftArrow(carouselCounter) + 'tracks-s3-wrapper-arrow-left'}>Left Arrow</FaArrowLeft>
            <FaArrowRight size="45px" onClick={() => {
                moveCarousel('right')
            }} className={hideRightArrow(carouselCounter) + 'tracks-s3-wrapper-arrow-right'}>Right Arrow</FaArrowRight>
            <div className="tracks-s3 d-flex flex-wrap justify-content-center" id="tracks-s3-carousel">
                {
                    tracks.map((track => {
                        return <div className="tracks-s3-card" key={"tracks-key-" + track.title}>
                            <div
                                className="tracks-s3-card-img align-items-center d-flex justify-content-center justify-content-md-start">
                                <img src={track.trackImg} className="img-fluid ml-md-5 pl-md-5"
                                     alt={track.title + " track"}/>
                            </div>
                            <span className="m-md-3 text-center text-md-left">
                                           <img src={returnImg} className="d-none d-md-inline-block"
                                                alt={"return key"}/>
                                           <h4 className="d-inline-block ml-md-5">{track.title}</h4>
                                       </span>
                            <p>{track.text}</p>
                        </div>
                    }))
                }
            </div>
        </div>
    )
}

export default Trackss3

