import React from 'react';
import Trackss3 from './trackss3'
import partnerTron from "../images/hackathons4/partner-tron.png";
import partnerHuobi from "../images/hackathons4/partner-huobi.png";
import HackerText from "./hackerText";
const Prizess3 = () => {

    return (
        <>
            <div id="tracks" className="row prizes text-left no-gutters">
                <div className="col-sm-12 px-3">
                    <h2>Total Prizes <span className="text-white px-3">500,000</span> in USDD,
                        Across 6 Tracks</h2>
                </div>
            </div>
            <div className="align-items-center justify-content-center mb-5 mx-auto" style={{maxWidth: '1920px'}}>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="tracks-s3-prize-card">
                        <h4 className="tracks-s3-prize-card-rank text-left">1st Prize</h4>
                        <h4 className="tracks-s3-prize-card-amount">20,000 USDD</h4>
                    </div>
                    <div className="tracks-s3-prize-card">
                            <h4 className="tracks-s3-prize-card-rank text-left">2nd Prize</h4>
                            <h4 className="tracks-s3-prize-card-amount">15,000 USDD</h4>
                    </div>
                    <div className="tracks-s3-prize-card">
                            <h4 className="tracks-s3-prize-card-rank text-left">3rd Prize</h4>
                            <h4 className="tracks-s3-prize-card-amount">10,000 USDD</h4>
                    </div>
                    <div className="tracks-s3-prize-card">
                            <h4 className="tracks-s3-prize-card-rank text-left">4th Prize</h4>
                            <h4 className="tracks-s3-prize-card-amount">8,000 USDD</h4>
                    </div>
                    <div className="tracks-s3-prize-card">
                            <h4 className="tracks-s3-prize-card-rank text-left">5th Prize</h4>
                            <h4 className="tracks-s3-prize-card-amount">6,000 USDD</h4>
                    </div>
                </div>
            </div>
            <div className="row no-gutters">
                <div className="col-sm-12 px-3">
                    <p className="mx-auto prizes-disclaimer text-center">Hosted by DoraHacks - Global hacker community platform</p>
                </div>
            </div>
            <div className="mx-auto w-100" style={{maxWidth: '1920px'}}>
                <Trackss3/>
            </div>
            <div className="w-100 pb-4 mx-auto" style={{maxWidth: '1920px'}}>
                <HackerText id="highlights"
                            className="w-100 text-center section-header font-weight-bold mb-5 pt-5"
                            tag="h2" text="Highlights" triggerStart="bottom 95%"/>
                <p className="section-description mx-auto" style={{maxWidth: '982'}}>Winners also get priority on being listed on Huobi along with TRON DAO Ventures.</p>
            </div>
            <div className="mx-auto w-100" style={{maxWidth: '1920px'}}>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="px-1 tracks-s3-partner-card">
                        <img src={partnerTron} alt="Tron Partner Logo" className="img-fluid"/>
                    </div>
                    <div className="px-1 tracks-s3-partner-card">
                        <img src={partnerHuobi} alt="Huobi Partner Logo" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Prizess3

