import React, {useRef} from 'react';
import {graphql} from "gatsby"
import Judges from "../components/judges"
import Prizess3 from '../components/prizess3'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import HackerText from '../components/hackerText';
import {Helmet} from "react-helmet"
import Winnerss3 from "../components/winnerss3";
import CountdownTimers3 from "../components/countdownTimers3";
import hackathonlogo from '../images/hackathons4/TRONxHuobi Logo.png';
import PartnerIconsShort from "../components/partners-icons-short";
import Winnerss3v2 from "../components/winnerss3v2";
import doraLogo from "../images/hackathons4/dora-logo.png"

const Hackathon = ({pageContext: {t}, data: {allWpPartner, allGrandPrize, allWinners}}) => {
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);


  return (
      <>
        <Helmet>
          <title>HackaTRON | TRONDAO</title>
          <meta name="description"
                content="TRON Grand Hackathon where blockchain developers get to compete to build decentralized apps. See past winners and compete in the next season here."/>
          <meta property="og:description"
                content="TRON Grand Hackathon where blockchain developers get to compete to build decentralized apps. See past winners and compete in the next season here."/>
          <meta property="og:title" content="Hackathon | TRONDAO"/>
        </Helmet>
        <div className="index hackathons4" ref={ref}>
          <div className="hackathons4-header">
            <div>
              <div className="row pt-5 pb-4 no-gutters">
                <div className="col-12 text-center">
                  <div className="hackathons4-title-small">Season 4</div>
                  <h1 className="hackathons4-title">Hacka<span className="text-danger">TRON</span></h1>
                  <img src={hackathonlogo} className="img-fluid" alt="hackathon logo"/>
                </div>
              </div>

            </div>
            <CountdownTimers3/>
            <div className="row no-gutters my-5 justify-content-center">
              <div style={{maxWidth: '306px'}}>
                <img className="img-fluid mb-3" alt="Dora Logo" src={doraLogo}/>
              </div>
            </div>

          </div>
          <div className="">
            <Prizess3/>
          </div>
          <div className="">
            <div className="mx-auto pb-5 hero-padding" style={{maxWidth: '1920px'}}>
              <div
                  className="pb-5 d-flex flex-column align-items-center justify-content-center text-decoration-none text-white">
                <HackerText id="partners" className="w-100 text-center section-header font-weight-bold mb-5"
                            tag="h2" text="Partners" triggerStart="bottom 95%"/>
                <div className="section-description mx-auto">Crypto’s most prestigious institutions come
                  together to offer their expertise as they judge
                  all around blockchain inventions and accelerate the growth of the TRON DAO ecosystem.
                </div>
              </div>
              <PartnerIconsShort data={allWpPartner}/>
            </div>
          </div>
          <div className="">
            <div className="w-100 pb-4 mx-auto" style={{maxWidth: '1920px'}}>
              <HackerText id="judges"
                          className="w-100 text-center section-header font-weight-bold mb-5 pt-5"
                          tag="h2" text="Judges" triggerStart="bottom 95%"/>
              <p className="section-description mx-auto" style={{maxWidth: '1300px'}}>Experts from across the
                blockchain industry bring multi-perspective thought leadership in
                evaluating the wide range of Hackathon projects.</p>
              <Judges/>
            </div>
          </div>
          <div className="my-5 mx-auto" style={{maxWidth: '1920px'}}>
            <Winnerss3v2 data={allGrandPrize}/>
          </div>
          <div className="mb-5 mx-auto" style={{maxWidth: '1920px'}}>
            <Winnerss3 data={allWinners}/>
          </div>
        </div>
      </>
  )

}

export default Hackathon

export const query = graphql`
query {  
    allGrandPrize: allWpProject(
    filter: {hackathonProjectInfo: {winnersPrize: {eq: "1st Place"}, projectAWinner: {eq: "Yes"}}}
  ) {
    nodes {
      title
      hackathonSeasonsAndTracks {
        hackathonSeason
        hackathonTrack
      }
      hackathonProjectInfo {
        projectAWinner
        projectDescription
        winnersPrize
        hackathonTeamPic {
          sourceUrl
        }
      }
      slug
    }
  }
    allWinners: allWpProject (sort: {fields: hackathonProjectInfo___winnersPrize} 
  ) {
    nodes {
      title
      hackathonSeasonsAndTracks {
        hackathonSeason
        hackathonTrack
      }
      hackathonProjectInfo {
        projectAWinner
        projectDescription
        winnersPrize
        hackathonTeamPic {
          sourceUrl
        }
      }
      slug
    }
  }
  allWpArticle {
    nodes {
      title
      hackathonSeasonsAndTracks {
        hackathonSeason
        hackathonTrack
      }
      articleInfo {
        articlePic {
          sourceUrl
        }
        articleUrl
      }
    }
  }
  allWpPartner(sort: {order: ASC, fields: menuOrder}) {
  edges {
    node {
      id
      uri
      title
      hackathonSeasonsAndTracks {
        hackathonSeason
        hackathonTrack
      }
      partnerInfo {
        partnerUrl
        partnerBio
        partnerLogo {
          sourceUrl
        }
      }
    }
  }
}
}

`